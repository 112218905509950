export const URL_ATTACHMENT = 'attachments/download-file';
export const URL_ATTACHMENT_LOAD_CALCULATION = 'service/download-load-calculation';
export const URL_ATTACHMENT_ENGINEERING_SHEET = 'service/download-engineering-sheet';
export const URL_ATTACHMENT_COLOR_SELECTION = 'service/download-color-selection';
export const URL_EXPORT_PRODUCT = 'product/export-excel';
export const URL_ATTACHMENT_CONTRACT = 'service/download-contract';
export const URL_ATTACHMENT_HOMEOWNER_RESPONSABILITY = 'service/download-homeowner-responsability';

export const TYPE_ATTACHMENT = {
    SERVICE:                                { value: 1, description: 'Service' },
    PRODUCT:                                { value: 2, description: 'Product' },
    LOAD_CALCULATION:                       { value: 3, description: 'Load calculation' },
    DRAFTING:                               { value: 4, description: 'Drafting' },
    PERMITTING:                             { value: 5, description: 'Permitting' },
    PROJECT:                                { value: 6, description: 'Technical specification' },
    WORK_ORDER:                             { value: 7, description: 'Work order' },
    INSPECTION:                             { value: 8, description: 'Inspection' },
    ACTIVITIES:                             { value: 9, description: 'Activities' },
    PROMOTION:                              { value: 10, description: 'Promotion' },
    COLOR_SELECTION:                        { value: 11, description: 'Color selection' },
    SERVICE_CALLS:                          { value: 12, description: 'Service calls' },
    CUSTOMER:                               { value: 13, description: 'Customer' },
};

export const TYPE_DOCUMENT = {
    PROPOSAL:                               { value: 1, description: 'Proposal' },
    SURVEY:                                 { value: 2, description: 'Survey' },
    POOL_STUDIO_DXF:                        { value: 3, description: 'Pool studio - dxf' },
    POOL_STUDIO_PDF:                        { value: 4, description: 'Pool studio - pdf' },
    ARCHITECTURAL_SITE_PLAN:                { value: 5, description: 'Architectural site plan' },
    DIMENSIONED_EQUIPMENT_LOCATION:         { value: 6, description: 'Equipment location' },
    FENCE_LOCATION:                         { value: 7, description: 'Fence location' },
    DEPH_DEEPEST_POINT_LOCATION:            { value: 8, description: 'Deph deepest point location' },
    LIGHT_LOCATIONS:                        { value: 9, description: 'Light locations' },
    SOIL_TEST:                              { value: 10, description: 'Soil test' },
    PERGOLAS:                               { value: 11, description: 'Pergolas' },
    WATER_FEATURES:                         { value: 12, description: 'Water features' },
    BBQ:                                    { value: 13, description: 'Bbq' },
    PRODUCT:                                { value: 14, description: 'Product' },
    POOL_STUDIO_IMAGES:                     { value: 15, description: 'Pool studio images' },
    DRAFTING_POOL_PLAN:                     { value: 18, description: 'Drafting pool plan' },
    DRAFTING_DESIGN_SPECIFICATION:          { value: 19, description: 'Drafting design specification' },
    DRAFTING_IMAGES:                        { value: 20, description: 'Drafting images' },
    DRAFTING_POOL_PLAN_SIGNED:              { value: 21, description: 'Drafting pool plan signed' },
    DRAFTING_POOL_PLAN_ENGINEER_SIGNED:     { value: 22, description: 'Drafting pool plan engineer signed' },
    LOAD_CALCULATION_PICTURES:              { value: 23, description: 'Load calculation pictures' },
    DRAFTING_BARBECUE:                      { value: 24, description: 'Drafting barbecue' },
    DRAFTING_PERGOLAS:                      { value: 25, description: 'Drafting pergolas' },
    DRAFTING_DRIVEWAY:                      { value: 26, description: 'Drafting driveway' },
    DRAFTING_LOAD_CALCULATION_SIGNED:       { value: 27, description: 'Drafting load calculation engineer signed' },
    PERMITTING_DOCUMENT_MODEL:              { value: 28, description: 'Permitting document model' },
    ADDENDUM:                               { value: 29, description: 'Addendum' },
    COLOR_SELECTION:                        { value: 30, description: 'Color selection' },
    PERMITTING_ONGOING_FILE:                { value: 31, description: 'Permtting ongoing file' },
    PERMITTING_NOC:                         { value: 32, description: 'Permtting noc' },
    WORK_ORDER_DETAIL:                      { value: 33, description: 'Work order detail' },
    WORK_ORDER_HISTORY:                     { value: 34, description: 'Work order history' },
    INSPECTION:                             { value: 35, description: 'Inspection' },
    ACTIVITIES_DOCUMENT:                    { value: 36, description: 'Activities document' },
    ACTIVITIES_PHOTOS:                      { value: 37, description: 'Activities photos' },
    PROMOTION:                              { value: 38, description: 'Promotion' },
    PRODUCT_DOCS:                           { value: 39, description: 'Product docs' },
    NOTE:                                   { value: 40, description: 'Note' },
    COLOR_SELECTION_PICTURES:               { value: 41, description: 'Color selection pictures' },
    PERMITTING_DRIVES_LICENSE:              { value: 42, description: 'Drive license' },
    PERMITTING_PERMIT_APPLICATION:          { value: 43, description: 'Permit application' },
    PERMITTING_PERMIT_CARD:                 { value: 44, description: 'Permit card' },
    PERMITTING_PLANS_APPROVED:              { value: 45, description: 'Plans approved' },
    SERVICE_CALLS_PICTURES:                 { value: 46, description: 'Service calls pictures' },
    SERVICE_CALLS_NOTES_PICTURES:           { value: 47, description: 'Service calls notes pictures' },
    PERGOLAS2:                              { value: 48, description: 'Pergolas 2' },
    PERGOLAS3:                              { value: 49, description: 'Pergolas 3' },
    PERGOLAS4:                              { value: 50, description: 'Pergolas 4' },
    PERGOLAS5:                              { value: 51, description: 'Pergolas 5' },
    PERGOLAS6:                              { value: 52, description: 'Pergolas 6' },
    BBQ2:                                   { value: 53, description: 'Bbq 2' },
    BBQ3:                                   { value: 54, description: 'Bbq 3' },
    BBQ4:                                   { value: 55, description: 'Bbq 4' },
    BBQ5:                                   { value: 56, description: 'Bbq 5' },
    BBQ6:                                   { value: 57, description: 'Bbq 6' },
    BBQ7:                                   { value: 58, description: 'Bbq 7' },
    BBQ8:                                   { value: 59, description: 'Bbq 8' },
    BBQ9:                                   { value: 60, description: 'Bbq 9' },
    BBQ10:                                  { value: 61, description: 'Bbq 10' },
    BBQ11:                                  { value: 62, description: 'Bbq 11' },
    PERMITTING_SPECS:                       { value: 63, description: 'Specs' },
    PERMITTING_SOIL_REPORT:                 { value: 64, description: 'Soil report' },
    RECORDED_NOC:                           { value: 65, description: 'Recorded noc' },
    SIGNED_AND_SEALED_PLANS:                { value: 66, description: 'Signed and sealed plans with city approval' },
    APPROVED_PERMIT:                        { value: 67, description: 'Approved permit' },
    SUPPORTING_DOCUMENTS:                   { value: 68, description: 'Supporting documents' },
    CUSTOMER_DOCUMENTS:                     { value: 69, description: 'Customer documents' },
    CONTRACT:                               { value: 70, description: 'Contract' },
    HOMEOWNER_RESPONSABILITY:               { value: 71, description: 'Homeowner responsability' },
};

export const CATEGORY_TEAM = {
    DRAFTER:                                { value: 1, description: 'Drafter' },
    ENGINEER:                               { value: 2, description: 'Engineer' },
    PERMITTER:                              { value: 3, description: 'Permitter' },
    PERMITTECH:                             { value: 4, description: 'Permit tech' },
    ELECTRICTECH:                           { value: 5, description: 'Electric tech' },
    PLUMBINGTECH:                           { value: 6, description: 'Plumbing tech' },
    GASTECH:                                { value: 7, description: 'Gas tech' },
};

export const CONTRACTOR_CATEGORY = {
    None:                                   { value: -1, description: 'None' },
    All:                                    { value: 0, description: 'All' },
    Engineer:                               { value: 1, description: 'Engineer' },
    Excavation:                             { value: 2, description: 'Excavation' },
    FormSteel:                              { value: 3, description: 'Form & Steel' },
    Gunite:                                 { value: 4, description: 'Gunite' },
    Plumbing:                               { value: 5, description: 'Plumbing' },
    Grading:                                { value: 6, description: 'Grading' },
    BBQCrew:                                { value: 7, description: 'BBQ Crew' },
    SandPaverDeliver:                       { value: 8, description: 'Sand/Paver Deliver' },
    Pergola:                                { value: 9, description: 'Pergola' },
    Landscape:                              { value: 10, description: 'Landscape' },
    Electric:                               { value: 11, description: 'Electric' },
    Equipment:                              { value: 12, description: 'Equipment' },
    Plaster:                                { value: 13, description: 'Plaster' },
    Leak:                                   { value: 14, description: 'Leak' },
    ITTComputer:                            { value: 15, description: 'ITT Computer' },
    Gas:                                    { value: 16, description: 'Gas' },
    Other:                                  { value: 99, description: 'Other' },
}

export const PROJECT_STATUS = {
    PENDING_ACCEPTANCE:                                                     0,
    UNASSIGNED:                                                             1,
    ASSIGNED:                                                               2,

    SALES_IN_PROGRESS:                                                      3,
    SALES_EXPIRED:                                                          4,
    SALES_WAITING_APPROVAL:                                                 5,
    SALES_FINISH_PROPOSAL:                                                  6,
    SALES_CONTRACTED:                                                       7,

    DRAFTING_REVIEW:                                                        8,
    DRAFTING:                                                               9,
    DRAFTING_APPROVAL:                                                      10,
    DRAFTING_FINAL_PLAN:                                                    11,
    DRAFTING_ENGINEERING:                                                   12,
    DRAFTING_NEEDS_APPROVAL:                                                13,

    PERMITTING_INCOMING_PROJECTS:                                           14,
    PERMITTING_PLAN_APPROVAL_PENDING:                                       15,
    PERMITTING_READY_SUBMIT:                                                16,
    PERMITTING_PERMIT_REVIEW:                                               17,
    PERMITTING_FILE_REVIEW:                                                 18,

    CONSTRUCTION_PREDIG_INCOMING_PROJECTS:                                  { id: 19, description: 'Incoming Projects' },
    CONSTRUCTION_PREDIG_PRESITE:                                            { id: 20, description: 'Pre Site' },
    CONSTRUCTION_PREDIG_LOCALES:                                            { id: 21, description: 'Locates' },
    CONSTRUCTION_PREDIG_PRESITE_RESP:                                       { id: 22, description: 'Pre Site Resp.' },
    CONSTRUCTION_PREDIG_REBAR:                                              { id: 23, description: 'Rebar' },

    CONSTRUCTION_FRONTEND_DIGDEMO:                                          { id: 24, description: 'Dig / Demo' },
    CONSTRUCTION_FRONTEND_FORMSTEEL:                                        { id: 25, description: 'Form & Steel' },
    CONSTRUCTION_FRONTEND_FRONTENDINSPECTION:                               { id: 26, description: 'Front End Inspection' },
    CONSTRUCTION_FRONTEND_PREPLUMB:                                         { id: 27, description: 'Pre Plumb' },
    CONSTRUCTION_FRONTEND_GUNITE:                                           { id: 28, description: 'Gunite' },
    CONSTRUCTION_FRONTEND_BACKFILL:                                         { id: 29, description: 'BackFill' },

    CONSTRUCTION_GRADINGPLUMBING_PLUMBING:                                  { id: 30, description: 'Plumbing' },
    CONSTRUCTION_GRADINGPLUMBING_PLUMBINGINSPECTION:                        { id: 31, description: 'Plumbing Inspection' },
    CONSTRUCTION_GRADINGPLUMBING_PATIOGRADE:                                { id: 32, description: 'Patio Grade' },
    CONSTRUCTION_GRADINGPLUMBING_DECKBONDINSPECTION:                               { id: 33, description: 'Deck Bond Inspection' },

    CONSTRUCTION_BRICKTILE_BRICKANDTILEINSTALL:                             { id: 34, description: 'Brick and Tile Install' },
    CONSTRUCTION_BRICKTILE_DECKINSTALL:                                     { id: 35, description: 'Deck Install' },
    CONSTRUCTION_BRICKTILE_FINALGRADE:                                      { id: 36, description: 'Final Grade' },
    CONSTRUCTION_BRICKTILE_CLEANUP:                                         { id: 37, description: 'Clean Up' },
    CONSTRUCTION_BRICKTILE_DECKINSPECTION:                                  { id: 38, description: 'Deck Inspections' },

    CONSTRUCTION_EQUIPMENTELETRIC_EQUIPMENTSET:                             { id: 39, description: 'Equipment Set' },
    CONSTRUCTION_EQUIPMENTELETRIC_ELECTRICHOOKUP:                           { id: 40, description: 'Electric Hook Up' },
    CONSTRUCTION_EQUIPMENTELETRIC_PREPLASTER:                               { id: 41, description: 'Pre Plaster Responsabilities' },
    CONSTRUCTION_EQUIPMENTELETRIC_INSPECTION:                               { id: 42, description: 'Final Inspections' },

    CONSTRUCTION_PLASTE_FINALWALKTHROUGHT:                                  { id: 43, description: 'Final Walkthrough' },
    CONSTRUCTION_PLASTE_VKBOWLDELIVERY:                                     { id: 44, description: 'VK Bowl Delivery and Install' },
    CONSTRUCTION_PLASTE_PREP:                                               { id: 45, description: 'Prep' },
    CONSTRUCTION_PLASTE_PLASTER:                                            { id: 46, description: 'Plaster' },
    CONSTRUCTION_PLASTE_OPENITEMS:                                          { id: 47, description: 'Open Items' },

    CONSTRUCTION_FENCE_SENDPOANDSCHEDULE:                                   { id: 48, description: 'Send PO and Schedule' },
    CONSTRUCTION_FENCE_BARRIERANDBONDINSPECTION:                            { id: 49, description: 'Barrier and Bond Inspection' },
    CONSTRUCTION_FENCE_FINALINSPECTION:                                     { id: 50, description: 'Final Inspection' },

    CONSTRUCTION_BBQ_UNDERGROUNDINSPECTION:                                 { id: 51, description: 'Underground Inspection' },
    CONSTRUCTION_BBQ_FRAMEINSTALLATION:                                     { id: 52, description: 'Frame Installation' },
    CONSTRUCTION_BBQ_FRAMEANDTIEDOWNINSPECTION:                             { id: 53, description: 'Frame and tie down inspection' },
    CONSTRUCTION_BBQ_INSTALLROUGHELECTRIC:                                  { id: 54, description: 'Install Rough Electric' },
    CONSTRUCTION_BBQ_ROUGHELECTRICINSPECTION:                               { id: 55, description: 'Rough Electric Inspection' },
    CONSTRUCTION_BBQ_DRYWALLINSTALLATION:                                   { id: 56, description: 'Drywall installation' },
    CONSTRUCTION_BBQ_DRYWALLANDTIEDOWNINSPECTION:                           { id: 57, description: 'Drywall and tie down inspection' },
    CONSTRUCTION_BBQ_CLADDINGANDCOUNTERTOPINSTALLATION:                     { id: 58, description: 'Cladding and countertop installation' },
    CONSTRUCTION_BBQ_INSTALLAPPLIANCES:                                     { id: 59, description: 'Install Appliances' },
    CONSTRUCTION_BBQ_FINALELECTRICINSTALLATION:                             { id: 60, description: 'Final Electric Installation' },
    CONSTRUCTION_BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION:          { id: 61, description: 'Final electric, final gas, final plumbing inspection' },
    CONSTRUCTION_BBQ_FINALINSPECTION:                                       { id: 62, description: 'Final inspection' },

    CONSTRUCTION_PERGOLA_FOOTERFRAME:                                       { id: 63, description: 'Pergola footer frame' },
    CONSTRUCTION_PERGOLA_INSPECTIONSFOOTERBONDFOOTERFORMSFOOTERFOUNDATION:  { id: 64, description: 'Inspections (FB, FF, FF)' },
    CONSTRUCTION_PERGOLA_FOOTERSPOURED:                                     { id: 65, description: 'Pergola footers poured' },
    CONSTRUCTION_PERGOLA_FOOTERFINALINSPECTION:                             { id: 66, description: 'Footer final inspection' },
    CONSTRUCTION_PERGOLA_COLUMNINSTALLATION:                                { id: 67, description: 'Pergola Column installation' },
    CONSTRUCTION_PERGOLA_ELECTRICINSTALLATION:                              { id: 68, description: 'Pergola electric installation' },
    CONSTRUCTION_PERGOLA_FRAMINGCOLUMNCOLUMNBONDFINALELECTRICINSPECTION:    { id: 69, description: 'Pergola FC, CB, FE Inspection' },
    CONSTRUCTION_PERGOLA_FINISHINSTALLATION:                                { id: 70, description: 'Finish Installation' },
    CONSTRUCTION_PERGOLA_FINALINSPECTION:                                   { id: 71, description: 'Final Inspection' },
    
    CONSTRUCTION_SERVICE_FIREUP:                                            { id: 72, description: 'Fire Up' },
    CONSTRUCTION_SERVICE_AFTERPLASTER:                                      { id: 73, description: 'After Plaster' },
    CONSTRUCTION_SERVICE_TURNOVER:                                          { id: 74, description: 'Turn Over' },
    CONSTRUCTION_SERVICE_INSPECTION:                                        { id: 75, description: 'Inspections' },
}

export const CONSTRUCTION_PANEL = {
    PREDIG:              1,
    FRONTEND:            2,
    GRADINGPLUMBING:     3,
    BRICKTILE:           4,
    EQUIPMENTELETRIC:    5,
    PLASTER:             6,
    FENCE:               7,
    BBQ:                 8,
    PERGOLA:             9,
    SERVICE:             10,
}
export const CONSTRUCTION_STAGE = {
    NO_STAGE:                                                       0,
    PREDIG_PRESITE:                                                 1,
    PREDIG_LOCALES:                                                 2,
    PREDIG_PRESITE_RESP:                                            3,
    PREDIG_REBAR:                                                   4,

    FRONTEND_DIGDEMO:                                               5,
    FRONTEND_FORMSTEEL:                                             6,
    FRONTEND_FRONTENDINSPECTION:                                    7,
    FRONTEND_PREPLUMB:                                              8,
    FRONTEND_GUNITE:                                                9,
    FRONTEND_BACKFILL:                                              10,

    GRADINGPLUMBING_PLUMBING:                                       11,
    GRADINGPLUMBING_PLUMBINGINSPECTION:                             12,
    GRADINGPLUMBING_PATIOGRADE:                                     13,
    GRADINGPLUMBING_BONDINGGRID:                                    14,

    BRICKTILE_SENDDELIVERY:                                         15,
    BRICKTILE_DECKINSTALL:                                          16,
    BRICKTILE_FINALGRADE:                                           17,
    BRICKTILE_CLEANUP:                                              18,
    BRICKTILE_DECKINSPECTION:                                       19,

    EQUIPMENTELETRIC_EQUIPMENTSET:                                  20,
    EQUIPMENTELETRIC_ELECTRICHOOKUP:                                21,
    EQUIPMENTELETRIC_PREPLASTER:                                    22,
    EQUIPMENTELETRIC_INSPECTION:                                    23,

    PLASTE_FINALWALKTHROUGHT:                                       24,
    PLASTE_VKBOWLDELIVERY:                                          25,
    PLASTE_PREP:                                                    26,
    PLASTE_PLASTER:                                                 27,
    PLASTE_OPENITEMS:                                               28,

    FENCE_SENDPOANDSCHEDULE:                                        29,
    FENCE_BARRIERANDBONDINSPECTION:                                 30,
    FENCE_FINALINSPECTION:                                          31,

    BBQ_UNDERGROUNDINSPECTION:                                      32,
    BBQ_FRAMEINSTALLATION:                                          33,
    BBQ_FRAMEANDTIEDOWNINSPECTION:                                  34,
    BBQ_INSTALLROUGHELECTRIC:                                       35,
    BBQ_ROUGHELECTRICINSPECTION:                                    36,
    BBQ_DRYWALLINSTALLATION:                                        37,
    BBQ_DRYWALLANDTIEDOWNINSPECTION:                                38,
    BBQ_CLADDINGANDCOUNTERTOPINSTALLATION:                          39,
    BBQ_INSTALLAPPLIANCES:                                          40,
    BBQ_FINALELECTRICINSTALLATION:                                  41,
    BBQ_FINALELECTRICFINALGASFINALPLUMBINGINSPECTION:               42,
    BBQ_FINALINSPECTION:                                            43,

    PERGOLA_FOOTERFRAME:                                            44,
    PERGOLA_INSPECTIONSFOOTERBONDFOOTERFORMSFOOTERFOUNDATION:       45,
    PERGOLA_FOOTERSPOURED:                                          46,
    PERGOLA_FOOTERFINALINSPECTION:                                  47,
    PERGOLA_COLUMNINSTALLATION:                                     48,
    PERGOLA_ELECTRICINSTALLATION:                                   49,
    PERGOLA_FRAMINGCOLUMNCOLUMNBONDFINALELECTRICINSPECTION:         50,
    PERGOLA_FINISHINSTALLATION:                                     51,
    PERGOLA_FINALINSPECTION:                                        52,

    SERVICE_FIREUP:                                                 53,
    SERVICE_AFTERPLASTER:                                           54,
    SERVICE_TURNOVER:                                               55,
    SERVICE_INSPECTION:                                             56,
}

export const EVENT_TYPE = {
    TARGET_DATE:                            0,
    COMPLETED_DATE:                         1,
    DEADLINE_DATE:                          2,
    ID_CONTRACTOR:                          3,
    CHECKBOX_LIST:                          4,
    FORM:                                   5
}

export const PDF_NAMES = {
    LOAD_CALCULATION:                       'LoadCalculation.pdf',
    ENGINEER_SHEET:                         'EngineeringSheet.pdf',
}

export const MODULES_NAMES = {
    SALES:                                  { id: 1, description: 'Sales' },
    DRAFTING:                               { id: 2, description: 'Drafting' },
    PERMITTING:                             { id: 3, description: 'Permitting' },
    CONSTRUCTION:                           { id: 4, description: 'Construction' },
    SERVICE:                                { id: 5, description: 'Service' },
}

export const TYPE_OF_WORK = {
    REMODEL:                                1,
    NEW_PROJECT:                            2,
    SERVICE:                                3
}